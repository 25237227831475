import './App.css';
import EthApp from './EthApp';



function App() {

  return (

    <EthApp />

  );
}

export default App;